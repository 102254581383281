.loaderContainer {
    /* position: absolute;
    /* background-color: white; */
    /* width: 100%;
    height: 100%; */
    /* left: 50%;
    top: 30%;
    transform: translateX(-10%);  */
    /* animation: colorChange 10s infinite; */
    content: "";
    width: 100%;
    height: 300px;
    border-radius: 0.5rem;

    @media (max-width: 600px) {
        height: 100px;
    }
    animation: colorChange 5s infinite;
}

@keyframes colorChange {
    0% {
        /* background-color: red; */
        background-color: #e5e7eb;
    }
    25% {
        background-color: #fecaca;
    }
    50% {
        background-color: #fde68a;
    }
    75% {
        background-color: #bae6fd;
    }
    100% {
        background-color: #f5d0fe;
    }
}

/* .imageContainer {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
} */

.loading {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #f1f1f1;
    animation: loadingAnimation 1s infinite;
}

@keyframes loadingAnimation {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(100%);
    }
}

.imageContainer {
    position: relative;
}

.image {
    display: none;
}

.image.loaded {
    display: block;
    animation: fade-in 1s forwards;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
