* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.header__second {
    font-size: 1rem;
    line-height: 3.5rem;
    font-weight: 500;
    text-transform: uppercase;
}

.bg-grad {
    background-image: linear-gradient(
        189deg,
        rgba(2, 0, 36, 1) 0%,
        rgba(226, 236, 247, 1) 0%,
        rgba(227, 224, 250, 1) 50%,
        rgba(219, 204, 244, 1) 100%
    );
}

.navLink {
    text-decoration: none;
}
input:focus {
    outline: none !important;
}
#footer {
    /* position: fixed; */
    bottom: 0;
    left: 0;
    width: 100%;
}

#middleBox {
    min-height: 90vh !important;
}

.loginLink {
    margin-left: 0.5rem;
}

.sighupLink {
    margin-left: 0.5rem;
}

.makeBorderRight {
    border-right: 1px solid rgb(235, 235, 235);
}

@media screen and (max-width: 768px) {
    .makeBorderRight {
        border-right: unset;
    }
    .smallBorder {
        border-bottom: 1px solid rgb(235, 235, 235);
        border: 1px solid rgb(235, 235, 235);
        border-radius: 5px;
        margin-bottom: 1rem;
    }
}

.boxShadow {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.boxShadow_dark {
    box-shadow: rgb(0, 0, 0) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.8) 0px 1px 3px -1px;
    background-color: #1a1a1a;
}

.lightboxImage.active {
    border: 2px solid red;
}

/* #aiseo__art{
    background-color: aiseo__art;
    color: white;
} */

.bottomImageWrapper::-webkit-scrollbar {
    width: 3px !important;
}

/* Track */
.bottomImageWrapper::-webkit-scrollbar-track {
    background: #000000;
}

/* Handle */
.bottomImageWrapper::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
/* @media screen and (min-width: 1440px){
  } */

.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 4.5px 14px !important;
}

@media screen and (max-width: 991px) {
    .ReviewGrid {
        margin-left: 0px;
        column-count: 3 !important;
    }
}

@media screen and (max-width: 767px) {
    .ReviewGrid {
        margin-left: 0px;
        column-count: 2 !important;
    }
}
@media screen and (max-width: 440px) {
    .ReviewGrid {
        margin-left: 0px;
        column-count: 1 !important;
    }
}
.inputSelection {
    background-color: rgb(232, 232, 232);
}
.inputSelection.active {
    background: linear-gradient(86.43deg, #9e6cf3 26.2%, #62c0e8 72.57%);
    font-weight: bold;
    color: white;
}
.dreamBoothSelectionImage {
    opacity: 0.6;
    padding: 5px 20px;
    border-radius: 13px;
}
.active.dreamBoothSelectionImage {
    border: 2px solid black;
    padding: "4px";
    opacity: 1;
}

::-webkit-scrollbar {
    width: 6.5px;
    cursor: context-menu;
}

::-webkit-scrollbar-track {
    background: transparent;
    cursor: context-menu;
}

::-webkit-scrollbar-thumb {
    background-color: #5b6474;
    border-radius: 10px;
    border: 3px solid transparent;
    cursor: context-menu;
}
