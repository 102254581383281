/* 

:root {
    --plyr-color-main: #6366f1;
}

.plyr__controls .plyr__volume input {
}

.plyr.plyr--full-ui.plyr--video {
    border-radius: 0.9rem;
    width: 1005;
    aspect-ratio: 16/9;
}
 */

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fade-in {
    animation: fadeIn 1s ease-in;
}
