.navLogoImg {
    width: auto;
    height: 1.8rem;
    margin-right: 0.5rem;
    cursor: pointer;

    @media (max-width: 600px) {
        width: 2.5rem;
        height: auto;
    }
}
