.css-u63xu-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
    background-color: #0f172a !important;
    color: white !important;
    border-radius: 10px !important;
    border: 2px solid #1e293b !important;
    padding: 0px !important;
}

.css-u63xu-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper li {
    padding: 8px 16px !important;
}

.css-u63xu-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper li:hover {
    background-color: #1e293b !important;
    color: white !important;

}