@keyframes slideInLeftToRight {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.video-snackbar-parent {
    z-index: 90;
    animation-name: slideInLeftToRight;
    animation-duration: 1s; /* Adjust the duration as desired */
    animation-fill-mode: forwards;
}
