.loader_icon {
    animation: upscale .75s ease infinite alternate;
}

@keyframes upscale {
    0% {
        width: 40px;
        height: 40px;
    }
    100% {
        width: 60px;
        height: 60px;
    }
}