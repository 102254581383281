@keyframes slideInLeftToRight {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

/* Apply the animation to your element */
/* .your-element {
    
  } */

.toast {
    position: fixed;
    padding: 10px 15px;
    color: white;
    border-radius: 10px;
    min-width: 150px;
    max-width: 100%;
    display: flex;
    gap: 0.3rem;
    justify-content: space-between;
    z-index: 90;
    animation-name: slideInLeftToRight;
    animation-duration: 1s; /* Adjust the duration as desired */
    animation-fill-mode: forwards;

    @media (max-width: 575.98px) {
        max-width: 60vw;
    }
}

.toastContent {
    display: flex;
    gap: 0.2rem;
    align-items: center;
}

.toastContent > span {
    display: flex;
    align-items: center;
    justify-content: center;
}

.toast > button {
    background-color: transparent;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.2rem;
    cursor: pointer;
    height: 1.5rem;
    margin-left: 0.6rem;
}

.toast-success {
    background-color: rgb(227, 248, 227);
    border: 2px solid rgb(1, 241, 45);
    color: rgb(0, 171, 3);
}

.toast-success > button {
    color: rgb(0, 171, 3);
    border: 1.5px solid rgb(1, 241, 45);
}

.toast-error {
    background-color: rgb(252, 223, 223);
    border: 2px solid rgb(255, 105, 105);
    color: rgb(255, 105, 105);
}

.toast-error > button {
    color: rgb(255, 105, 105);
    border: 1.5px solid rgb(255, 105, 105);
}

.toast-warning {
    background-color: rgb(255, 248, 210);
    color: rgb(28, 28, 4);
    border: 2px solid rgb(255, 223, 39);
}

.toast-warning > button {
    color: rgb(88, 88, 88);
    border: 1.5px solid rgb(255, 223, 39);
}

.toast-neutral {
    background-color: #d7d7ff;

    border: 2px solid #6363ff;
    color: #6363ff;
}

.toast-neutral > button {
    color: #6363ff;
    border: 1.5px solid #6363ff;
}

.toast-bottomRight {
    bottom: 100px;
    right: 20px;

    @media (max-width: 575.98px) {
        bottom: 20px;

        left: 20px;
        transform: none;
    }
}
.toast-bottomLeft {
    bottom: 20px;
    left: 20px;
}
.toast-bottomCenter {
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    @media (max-width: 575.98px) {
        bottom: 20px;

        left: 20px;
        transform: none;
    }
}
.toast-topRight {
    top: 80px;
    right: 20px;
    z-index: 10000;
    font-weight: bold;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
}
.toast-topLeft {
    top: 20px;
    left: 20px;
}
.toast-topCenter {
    top: 20px;
    right: 50%;
    transform: translateX(-50%);
}

.toast-center {
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    @media (max-width: 575.98px) {
        left: 15px;

        transform: translateY(-50%);
    }
}
