.imgUpSectionContainer {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.imgInputBox {
    height: 150px;
    border-radius: 2rem;
    overflow: hidden;
    width: 100%;
    max-width: 900px;
    margin-top: 1.5rem;

    @media (max-width: 575.98px) {
        width: 85vw;
        margin: 0 auto;
        margin-top: 1.5rem;
    }
}

.imgInputTextBox {
    position: relative;
    height: 100%;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.imgInputTextBox form {
    width: 60%;
    display: flex;
    gap: 0.5rem;
    @media (max-width: 575.98px) {
        width: 90%;
    }
}

.imgInputTextBox button {
    padding: 0.6rem;
    border-radius: 0.3rem;
    background-color: #14aaff;
    color: white;
    border: 2px solid #14aaff;
    cursor: pointer;
}

.imgInputTextBox input {
    width: 100%;
    padding: 0.5rem 1rem;
    border-radius: 0.4rem;
    border: 2px solid #a3eaff;
    background-color: #e8faff;
    font-size: 1rem;
}

.imgInputTextBox input:focus {
    border: 2px solid #14aaff;
}

.imgInputDropperBox {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    cursor: pointer;
}

.imgInputDropperBox > p {
    cursor: pointer;

    font-weight: 600;

    font-size: 1.1rem;
    text-align: center;
    opacity: 1;
    margin-left: 4px;
    margin-right: 4px;
    user-select: none;
    margin-bottom: 0rem;
}

.imgInputDropperBox > input {
    cursor: pointer;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
}

@media (min-width: 640px) {
    .imgInputDropperBox {
        padding-top: 16px;
        padding-bottom: 16px;
    }
}

.imgOptionalText {
    display: flex;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    gap: 0.25rem;
    align-items: center;
    justify-content: center;
}

.optionalImgContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;
    width: fit-content;
    margin: 0 auto;
}

.optionalImgContainer img {
    width: 140px;
    height: 140px;
    border-radius: 0.6rem;
    cursor: pointer;
}

.optionalImgContainer img:hover {
    opacity: 0.5;
}

@media (max-width: 575.98px) {
    .optionalImgContainer img {
        width: 60px;
        height: 60px;
    }
}

.imgPreviewContainer {
    background-color: #ededed;
    margin-top: 3rem;
    margin-bottom: 3rem;
    height: fit-content;
    padding: 3rem 0;
}

.imgPreviewBox {
    width: 500px;
    margin: 0 auto;
    @media (max-width: 575.98px) {
        width: 100%;
    }
}

.imgPreviewBox .canvasContainer {
    position: relative;

    width: 500px;
    height: 500px;
    margin: 0 auto;

    @media (max-width: 575.98px) {
        width: 80vw;
        height: 80vw;
        margin: 0 auto;
    }
}

.checkerboard {
    width: 100%;
    height: 100%;
    --checkerboard-black-color: #bfbfbf;
    --checkerboard-white-color: transparent;
    background-image: linear-gradient(
            45deg,
            var(--checkerboard-black-color) 25%,
            var(--checkerboard-white-color) 25%
        ),
        linear-gradient(
            -45deg,
            var(--checkerboard-black-color) 25%,
            var(--checkerboard-white-color) 25%
        ),
        linear-gradient(
            45deg,
            var(--checkerboard-white-color) 75%,
            var(--checkerboard-black-color) 75%
        ),
        linear-gradient(
            -45deg,
            var(--checkerboard-white-color) 75%,
            var(--checkerboard-black-color) 75%
        );
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0;
}

canvas {
    padding: 0px;
    margin: 0px;
    border: 0px;
    background: transparent;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 500px;
    height: 500px;
    display: block;
}

.canvasP1 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 500px;
    height: 500px;
    @media (max-width: 575.98px) {
        width: 80vw;
        height: 80vw;
    }
}

.imgPreviewFormBox {
    width: 500px;
    margin: 2rem auto;
    position: relative;
    height: 110px;

    @media (max-width: 575.98px) {
        width: 80vw;
    }
}

.imgPreviewFormBox textarea {
    box-sizing: border-box;
    width: 500px;
    padding: 1rem;
    height: 110px;
    resize: none;
    border: none;
    font-weight: 600;
    border-radius: 0.3rem;
    font-size: 0.875rem;
    line-height: 1.25rem;

    @media (max-width: 575.98px) {
        width: 80vw;
    }
}

.imgPreviewFormBox textarea:focus {
    outline: none;
}

.imgPreviewFormBox button {
    position: absolute;
    bottom: 2px;
    right: 2px;
    background-color: transparent;
    border: 0px;
    padding: 1.5px;
    color: black;
    cursor: pointer;
}

.imgPreviewFormButton {
    width: 500px;
    margin: 0 auto;
    text-align: right;

    @media (max-width: 575.98px) {
        width: 80vw;
    }
}

.imgPreviewFormButton > button {
    background-color: #1d4ed8;
    padding: 0.6rem 1rem;
    color: white;
    font-size: 13px;
    border: none;
    border-radius: 0.6rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 600;
    cursor: pointer;
}

.imgPreviewFormButton > button:hover {
    background-color: #3764df;
    transition: all 0.15s ease-in;
}

.imgPreviewFormButton > button > span {
    padding: 0.3rem 0.6rem;
    border-radius: 1rem;
    margin-left: 1rem;
    background-color: white;
    color: black;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 600;
}
