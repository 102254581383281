@import url("https://fonts.googleapis.com/css2?family=Jockey+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Istok+Web:wght@400;700&display=swap");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.newBg {
  background-image: url(../src/imgs/createbttnhover.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.newBg2 {
  background-image: url(../src/imgs/AISEO_landing_page.png);
  background-position: center;
  /* background-size: cover; */
  background-repeat: no-repeat;
  padding: 0;
}
.product-landing {
  background-image: url(../src/imgs/product.png);
  background-position: center;
  /* background-size: cover; */
  background-repeat: no-repeat;
  padding: 0;
  /* margin-top: -50px; */
}

.bg-light-blue {
  background: #edf6ff;
}

.header__second {
  font-size: 1rem;
  line-height: 3.5rem;
  color: #616161;
  font-weight: 500;
  text-transform: uppercase;
}

.bg-grad {
  background-image: linear-gradient(
    to bottom right,
    rgba(246, 235, 255, 0.9),
    rgba(235, 246, 255, 0.9)
  );
}

.navLink {
  text-decoration: none;
}

.bg-light-blue {
  background: #edf6ff;
}

.header__second {
  font-size: 1rem;
  line-height: 3.5rem;
  color: #616161;
  font-weight: 500;
  text-transform: uppercase;
}

.bg-grad {
  background-image: linear-gradient(
    to bottom right,
    rgba(246, 235, 255, 0.9),
    rgba(235, 246, 255, 0.9)
  );
}

.navLink {
  text-decoration: none;
}
.image_section {
  background-color: #424d59;
  width: 100%;
}

/* fix the footer */

/* @media screen and (min-width: 1440px) {
  #footer {
    position: fixed !important;
    background-color: red !important;
  }
} */

.AppsumoBox {
  display: "none";
}

.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 4.5px 14px !important;
}

/* overlay css */
.container {
  position: relative;
  /* width: 50%; */
}

.image {
  /* display: block; */
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #008cba;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.5s ease;
}

.container:hover .overlay {
  height: 100%;
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.main-title {
  color: #2d2d2d;
  text-align: center;
  text-transform: capitalize;
  padding: 0.7em 0;
}

.container {
  /* padding: 1em 0; */
  /* float: left; */
  /* width: 50%; */
  width: 100%;
}

.container .title {
  color: #1a1a1a;
  text-align: center;
  margin-bottom: 10px;
}

.content {
  position: relative;
  /* width: 100%; */
  /* max-width: 400px;  */
  margin: auto;
  overflow: hidden;
  min-height: 300px;
  max-height: 70vh;
  display: flex;
  align-items: center;
}

.content .content-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.content:hover .content-overlay {
  opacity: 1;
}

.content-image {
  /* width: 100%; */
  max-width: 100%;
  display: flex;
  margin-left: auto;
  margin-right: auto;

  /* .content-image {
  width: 100%; */
}

.content-details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.content:hover .content-details {
  top: 90%;
  left: 50%;
  opacity: 1;
}

.content-details h3 {
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.15em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}

.content-details p {
  color: #fff;
  font-size: 0.8em;
}

.fadeIn-bottom {
  top: 80%;
}

.fadeIn-top {
  top: 20%;
}

.fadeIn-left {
  left: 20%;
}

.fadeIn-right {
  left: 80%;
}

/* side scrollbar colro change  */
html {
  --scrollbarBG: #cfd8dc;
  --thumbBG: #92a0a7;
}

/* ::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 10px;
  border: 1px solid var(--scrollbarBG);
} */
::-webkit-scrollbar {
  width: 7px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: rgb(91, 100, 116);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: rgb(65, 86, 107);
}

.scrollP::-webkit-scrollbar {
  width: 14px;
}
.selectedImage::-webkit-scrollbar {
  width: 5px !important;
}
.scrollP,
.selectedImage {
  /* scrollbar-width: thin; */
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.scrollP::-webkit-scrollbar-track,
.selectedImage::-webkit-scrollbar-track {
  background: transparent;
}
.scrollP::-webkit-scrollbar-thumb,
.selectedImage::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 10px;
  border: 1px solid var(--scrollbarBG);
}

.Line {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.preimage_card {
  transition: 0.4s ease-in-out;
}
.preimage_card:hover {
  transform: scale(1.03);
}
.boxPad {
  padding: 40px;
}

/* banner  */
.gradientColor {
  background: -webkit-linear-gradient(86.43deg, #9052fa 26.2%, #2cbfff 72.57%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradientColor2 {
  background: -webkit-linear-gradient(86.43deg, #e05b0e 26.2%, #be15dc 72.57%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
/* banner  */

/* products landing page */
.productBtn {
  display: inline-block;
  background-color: #7324fc;
  padding: 10px 20px;
  color: white;
  text-decoration: none;
  margin: 20px 0px;
  border-radius: 5px;
}
.productBtn:hover {
  background-color: #8042eb;
}
/* products landing page */

@media only screen and (max-width: 1024px) {
  #autocomplete {
    display: none !important;
  }
  .pt5 {
    margin-top: 20px !important;
  }
  #borderBox {
    min-width: 500px !important;
    overflow-x: scroll !important;
  }
  .boxPad {
    padding: 0px;
  }
}

@media only screen and (max-width: 768px) {
  .aiavatarLogo {
    display: none;
  }
  .templateBNT {
    display: flex;
    flex-direction: column;
  }
}

.blingBtn {
  border: 5px solid #a191fd;
  animation-name: blinking;
  font-size: 14px;
  font-weight: bold;
  padding: 2px 20px;
  border-radius: 5px;

  animation-name: blinking;
  animation-duration: 2s;
  animation-iteration-count: 100;
}
@keyframes blinking {
  50% {
    border-color: #1ff4f4;
  }
}

.containerimg {
  position: relative;
  width: 100%;
  cursor: pointer;
}

#gfg-img {
  width: 100%;
  display: block;
  transition: 0.4s ease-in-out;
  border-radius: 10px;
}
#gfg-img:hover {
  transform: scale(1.04);
}

.title {
  background-color: rgba(0, 0, 0, 0.56);
  position: absolute;
  bottom: 0;
  color: white;
  width: 100%;
  font-size: 18px;
  padding: 15px 0;
  text-align: center;
  opacity: 0;
  transition: 0.6s;
  font-family: monospace;
}

.containerimg:hover .title {
  /*becomes visible on hover*/
  opacity: 1;
}

.modelBtn {
  border: 1px solid #e4e4e4;
  padding: 10px 10px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  color: white;
  width: 170px;
  display: block;
  cursor: pointer;
  transition: 0.4s ease-in-out;
  background: linear-gradient(
    to bottom right,
    rgb(25, 80, 232),
    rgb(198, 20, 233)
  );
  font-family: system-ui;
}
.modelBtn:hover {
  background: linear-gradient(
    to top right,
    rgb(216, 67, 246),
    rgb(33, 81, 213)
  );
}

.modelNo {
  font-size: 16px;
  color: #b7bff7;
  margin-left: 5px;
}

.imgHov {
  transition: 0.4s ease-in-out;
  transform: scale(0.96);
  border-radius: 6px;
}

.imgHov:hover {
  transform: scale(1);
}

#stockText {
  border: 1px solid #e6e6e6;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
}

.h1s {
  font-family: sans-serif;
  font-size: 20px;
  margin: 20px 0px 10px;
  font-weight: 800;
  color: #666;
  margin-bottom: 10px;
}

.boldS {
  font-family: Inter;
  font-weight: 100;
}

.module-border-wrap {
  max-width: 250px;
  /* padding: 1rem; */
  position: relative;
  background: linear-gradient(to right, rgb(100 136 255), rgb(158 121 249));
  padding: 3px;
  cursor: pointer;
  transition: 0.4s ease-in-out;
  border-radius: 4px;
}
.module-border-wrap:hover {
  max-width: 250px;
  /* padding: 1rem; */
  background: linear-gradient(to right, rgb(143, 52, 247), rgb(208, 92, 231));
  position: relative;

  cursor: pointer;
}

.module {
  /* background: #91919169; */
  color: white;
  /* padding: 0.5rem; */
  font-family: math;
  font-weight: bold;

  display: flex;
  align-items: center;
  padding-right: 11px;
}
.stockimage {
  height: 90vh;
  object-fit: cover;
}

.headone {
  text-align: center;
  font-size: 48px!important;
  font-weight: bold!important;
  color: #031987;
  font-family: math!important;
}

.brand {
  background: linear-gradient(to right, #7a83ff 0%, #21c1e5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: sans-serif;
}

.boxCl {
}

.headPopp{
  text-align: center;
  font-size: 24px!important;
  color: #050568;
  margin-top: 41px;
  margin-bottom: 10px!important;
}
.headPopp2{
  text-align: center;
  font-size: 18px!important;
  color: #4242699f;
  margin-bottom: 15px;
  width: 62%;
  margin: 0 auto!important;
  font-weight: 600!important;
  font-family: system-ui!important;
  line-height: 20px!important;
}

label#popupLabel {
  font-family: system-ui;
  font-size: 20px;
}

#cars {
  width: 100%;
  margin-top: 20px;
  padding: 15px;
  font-size: 19px;
  border: 1px solid #e0dddd;
  border-radius: 3px;
}
#cars:focus{
  outline: none;
}

.popupButton {
  border: 1px solid rgb(100 136 255);
    padding: 8px 35px;
    color: rgb(100 136 255);
    font-weight: 700;
    background: rgb(232 237 255);
}

@media only screen and (max-width: 700px) {
  .generate_wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
}
